@import '@fontsource/poppins/300.css';
@import '@fontsource/poppins/400.css';
@import '@fontsource/poppins/500.css';
@import '@fontsource/poppins/600.css';
@import '@fontsource/montserrat/400.css';
@import '@fontsource/sacramento/400.css';
@import '@fontsource/libre-baskerville/400.css';
@import '@fontsource/tangerine/400.css';
@import '@fontsource/loved-by-the-king/400.css';
@import './style.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /*
     * the colors in this file are duplicated in the packages/config/tailwind/tailwind.config.ts
     * file which defines Tailwind utility classes for all of our brand colors. If you add one
     * here, please make sure to add it to that file as well. You will also have to update the
     * values in our Tailwind "gradients" plugin config, since that references our CSS custom properties
     * as well
     */
    /* figma colors */
    --color-primary: hsl(188 100% 10%);
    --color-text-primary: hsl(188 100% 10%);
    --color-text-secondary: hsl(190 13% 38%);
    --color-surface-primary: hsl(0 0 100%);
    --color-surface-secondary: hsl(200 16% 96%);
    --color-surface-accent: hsl(188 100% 90%);
    --color-surface-active: hsl(195 12% 93%);
    --color-line: hsl(189 13% 80%);
    --color-error: hsl(7 100% 61%);
    --color-image-outline: hsl(0 0% 0%);
    /* other brand colors */
    --color-primary-60: hsl(189 13% 51%);
    --color-primary-10: hsl(188 100% 90%);
    --color-tonal-90: hsl(191 13% 26%);
    --color-tonal-60: hsl(190 13% 38%);
    --color-tonal-30: hsl(189 13% 80%);
    --color-tonal-10: hsl(200 16% 96%);
    --color-white: hsl(0 0% 100%);
    --color-black: hsl(0 0% 0%);
    --color-blue: hsl(188 100% 50%);
    --color-blue-light: hsl(188 76% 87%);
    --color-blue-dark: hsl(202 100% 35%);
    --color-purple: hsl(284 100% 82%);
    --color-yellow: hsl(79 100% 78%);
    --color-orange: hsl(26 100% 72%);
    --color-gray-light: hsl(195 13% 88%);
    --color-gray-dark: hsl(191 13% 75%);
  }

  :focus-visible {
    outline: var(--color-text-secondary) auto 1px;
    outline-offset: 2px;
  }
}

/* If we are in dev mode, use a background image with a 1x1 pixel to show where the images are */
html[data-debug='true'] img {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mO88+jJfgAIpwNjOIHQWQAAAABJRU5ErkJggg==);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
