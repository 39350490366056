@font-face {
  font-family: 'LazyDog';
  /*Can be any text*/
  src: local('Lazydog'), url('./fonts/Lazydog.woff') format('woff');
}

.switch {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 40px;
}

.switch.small {
  width: 55px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 40px;
}

.switch.small > .slider {
  border-radius: 25px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 40px;
  width: 40px;
  left: 0px;
  bottom: 0px;
  background-color: #f7f7f7;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
  box-shadow: 1px 1px 4px 2px #bebebe;
}

.switch.small > .slider:before {
  height: 25px;
  width: 25px;
}

input:checked + .slider {
  background-color: #fff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #fff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(40px);
  -ms-transform: translateX(40px);
  transform: translateX(40px);
}

.switch.small > input:checked + .slider:before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}

.filter_heading {
  font-family: 'LazyDog', 'Courier New', Courier, monospace;
}

.display_container {
  width: 100%;
  cursor: text;
  height: 1.25rem;
  position: relative;
  word-break: break-word;
}

.display_container input,
.display_container textarea {
  position: absolute;
  width: 100%;
  z-index: 2;
  padding: 0;
  margin: 0;
  opacity: 0;
  word-break: break-word;
}

.display_container input:focus,
.display_container textarea:focus {
  outline: none;
}

.blinking-line {
  display: inline-block;
  width: 1px;
  height: 100%;
  background-color: black;
  animation: blink 1s step-start infinite;
  vertical-align: middle;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.character_container {
  height: 100%;
  width: 100%;
  z-index: 1;
  position: relative;
  word-break: break-word;
}

.character_container p {
  margin: 0;
}

.character_container .placeholder {
  color: #aaa;
}

.display_container input,
.character_container .char {
  word-break: break-word;
  height: inherit;
}
